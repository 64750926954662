<template>
  <div v-show="show">
    <van-cell-group class="person_container">
      <van-field
        v-show="toggle"
        v-for="(value,key) in person"
        v-model="updata[key]"
        type="text"
        :key="key + '1'"
        :label="mapPerson[key]"
        :placeholder="'请输入' + mapPerson[key]"
        :disabled="(person[key] && typeof person[key] === 'object')"
        :icon="(person[key] && typeof person[key] === 'object') ? 'arrow-down' : ''"
        @click-icon="optionClick(person[key],key)"
      />
      <van-field
        v-show="!toggle"
        v-for="(value,key) in noperson"
        v-model="updata[key]"
        type="text"
        :key="key + '2'"
        :label="mapNoperson[key]"
        :placeholder="'请输入' + mapNoperson[key]"
        :disabled="(noperson[key] && typeof noperson[key] === 'object')"
        :icon="(noperson[key] && typeof noperson[key] === 'object') ? 'arrow-down' : ''"
        @click-icon="optionClick(noperson[key],key)"
      />
    </van-cell-group>

    <van-button
      size="normal"
      round
      @click="submit"
      style="width:100%;margin:30px 0;background:#3EB3F0;color:#fff"
    >保存</van-button>
    <!-- <button @click="click">click</button> -->
    <van-popup
      v-model="popupshow"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :item-height="60"
        :columns="columns"
        @confirm="onChange"
        @cancel="popupshow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
export default {
  props: {
    result: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      show: false,
      popupshow: false,
      toggle: false,
      optionsKey: null,
      columns: [],
      person: {},
      updata: {},
      mapPerson: {
        name: '客户名称', // String   名称  自然人姓名
        sex: '性别', // int      性别 详见：Gender
        nation: '民族', // String   民族
        idNumber: '身份证', // String   身份证号
        phone: '电话号码', // String   电话号码
        email: '电子邮箱', // String   电子邮箱
        category: '身份类型', // int      详见CategoryEnum : 0自然人 1法人单位 2非法人单位
        birthday: '生日', // datetime 生日
        address: '住所地', // String   住所   自然人住所 非自然人办公地址
        age: '年龄', // int      年龄
        profession: '职业', // String   职业
        workOffice: '工作单位', // String   工作单位
        qq: 'qq', // String   qq
        weChat: '微信', // String   微信
        otherPhone: '其他电话', // String   其他电话
        postalCode: '邮政编码', // String   邮政编码
        longResidence: '长居地', // String   长居地
        documentDelivery: '文书送达地', // String   文书送达地
        selfColumnValue: '自填项' // String   自填项值
      },
      // 非自然人
      noperson: {},
      mapNoperson: {
        name: '公司或大学', // String   公司或大学名称
        idNumber: '社会统一代码', // String   社会统一代码
        phone: '电话号码', // String   电话号码
        email: '电子邮箱', // String   电子邮箱
        category: '身份类型', // int      详见CategoryEnum :0自然人 1法人单位 2非法人单位
        representative: '代表人类型', // int      代表人类型，RepresentativeEnum
        address: '办公地址', // String   办公地址
        otherPhone: '其他电话', // String   其他电话
        postalCode: '邮政编码', // String   邮政编码
        longResidence: '长居地', // String   长居地
        documentDelivery: '文书送达地', // String   文书送达地
        selfColumnValue: '自填项', // String   自填项值
        manager: '负责人姓名', // String   负责人姓名
        position: '负责人职位' // String   负责人职位
      }
    }
  },
  watch: {
    result (_) {
      this.updata = _
      this.show = true

      this.person = {
        name: _.name, // String   名称  自然人姓名
        category: {
          key: _.category,
          options: ['自然人', '法人单位', '非法人单位']
        }, // int      详见CategoryEnum : 0自然人 1法人单位 2非法人单位
        idNumber: _.idNumber, // String   身份证号
        phone: _.phone, // String   电话号码
        sex: {
          key: _.sex,
          options: [
            '先生',
            '女士',
            '未知'
          ]
        }, // int      性别 详见：Gender
        age: _.age, // int      年龄
        nation: {
          key: _.nation,
          options: _.nationArray
        }, // String   民族
        birthday: _.birthday, // datetime 生日
        email: _.email, // String   电子邮箱
        address: _.address, // String   住所   自然人住所 非自然人办公地址
        profession: _.profession, // String   职业
        workOffice: _.workOffice, // String   工作单位
        qq: _.qq, // String   qq
        weChat: _.weChat, // String   微信
        otherPhone: _.otherPhone, // String   其他电话
        postalCode: _.postalCode, // String   邮政编码
        longResidence: _.longResidence, // String   长居地
        documentDelivery: _.documentDelivery, // String   文书送达地
        selfColumnValue: _.selfColumnValue // String   自填项值
      }
      this.noperson = {
        name: _.name, // String   公司或大学名称
        category: {
          key: _.category,
          options: ['自然人', '法人单位', '非法人单位']
        },
        idNumber: _.idNumber, // String   社会统一代码
        phone: _.phone, // String   电话号码
        email: _.email, // String   电子邮箱
        // int      详见CategoryEnum :0自然人 1法人单位 2非法人单位
        representative: {
          key: _.representative,
          options: [
            '法定代表人',
            '主要负责人'
          ]
        }, // int      代表人类型，RepresentativeEnum
        address: _.address, // String   办公地址
        otherPhone: _.otherPhone, // String   其他电话
        postalCode: _.postalCode, // String   邮政编码
        longResidence: _.longResidence, // String   长居地
        documentDelivery: _.documentDelivery, // String   文书送达地
        selfColumnValue: _.selfColumnValue, // String   自填项值
        manager: _.manager, // String   负责人姓名
        position: _.position // String   负责人职位
      }
      if (_.category === 'NATURAL_PERSON') {
        this.updata.category = '自然人'
      }
      if (_.category === 'LEGAL_ENTITY') {
        this.updata.category = '法人单位'
      }
      if (_.category === 'NON_LEGAL_ENTITY') {
        this.updata.category = '非法人单位'
      }
      if (_.sex === 'MALE') {
        this.updata.sex = '先生'
      }
      if (_.sex === 'FEMALE') {
        this.updata.sex = '女士'
      }
      if (_.sex === 'UNKNOWN') {
        this.updata.sex = '未知'
      }
      if (_.representative === 'LEGAL_REPRESENTATIVE') {
        this.updata.representative = '法定代表人'
      }
      if (_.representative === 'LEGAL_ENTITY_PRINCIPAL') {
        this.updata.representative = '主要负责人'
      }
    }
  },
  methods: {
    optionClick (item, key) {
      this.columns = item.options
      this.popupshow = true
      this.optionsKey = key
    },
    onChange (picker, value, index) {
      this.updata[this.optionsKey] = this.columns[value]
      this.popupshow = false
      if (this.columns[value] === '自然人') {
        this.toggle = true
      } else {
        this.toggle = false
      }
    },
    async submit () {
      const _ = { ...this.updata }
      if (_.category === '自然人') {
        _.category = 'NATURAL_PERSON'
      }
      if (_.category === '法人单位') {
        _.category = 'LEGAL_ENTITY'
      }
      if (_.category === '非法人单位') {
        _.category = 'NON_LEGAL_ENTITY'
      }
      if (_.sex === '先生') {
        _.sex = 'MALE'
      }
      if (_.sex === '女士') {
        _.sex = 'FEMALE'
      }
      if (_.sex === '未知') {
        _.sex = 'UNKNOWN'
      }
      if (_.representative === '法定代表人') {
        _.representative = 'LEGAL_REPRESENTATIVE'
      }
      if (_.representative === '主要负责人') {
        _.representative = 'LEGAL_ENTITY_PRINCIPAL'
      }
      console.log(_)
      // const caseBase = '//192.168.0.124:10004'
      const response = await this.$axios.put(`${caseBase}//lts/customer/updateCustomer`, _)
      const { code } = response.data
      if (code === '200') {
        this.$toast.success('保存成功')
      } else {
        this.$toast.fail('保存失败')
      }
    }
  }
}
</script>

<style lang="stylus">
.person_container {
  border-radius: 5px;
  box-shadow: 0 0 1px #40B4E9;
  overflow: hidden;

  & .van-field__control {
    text-align: right;
  }
}
</style>
