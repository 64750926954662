var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
    },
    [
      _c(
        "van-cell-group",
        { staticClass: "person_container" },
        [
          _vm._l(_vm.person, function (value, key) {
            return _c("van-field", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toggle,
                  expression: "toggle",
                },
              ],
              key: key + "1",
              attrs: {
                type: "text",
                label: _vm.mapPerson[key],
                placeholder: "请输入" + _vm.mapPerson[key],
                disabled:
                  _vm.person[key] && typeof _vm.person[key] === "object",
                icon:
                  _vm.person[key] && typeof _vm.person[key] === "object"
                    ? "arrow-down"
                    : "",
              },
              on: {
                "click-icon": function ($event) {
                  return _vm.optionClick(_vm.person[key], key)
                },
              },
              model: {
                value: _vm.updata[key],
                callback: function ($$v) {
                  _vm.$set(_vm.updata, key, $$v)
                },
                expression: "updata[key]",
              },
            })
          }),
          _vm._l(_vm.noperson, function (value, key) {
            return _c("van-field", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.toggle,
                  expression: "!toggle",
                },
              ],
              key: key + "2",
              attrs: {
                type: "text",
                label: _vm.mapNoperson[key],
                placeholder: "请输入" + _vm.mapNoperson[key],
                disabled:
                  _vm.noperson[key] && typeof _vm.noperson[key] === "object",
                icon:
                  _vm.noperson[key] && typeof _vm.noperson[key] === "object"
                    ? "arrow-down"
                    : "",
              },
              on: {
                "click-icon": function ($event) {
                  return _vm.optionClick(_vm.noperson[key], key)
                },
              },
              model: {
                value: _vm.updata[key],
                callback: function ($$v) {
                  _vm.$set(_vm.updata, key, $$v)
                },
                expression: "updata[key]",
              },
            })
          }),
        ],
        2
      ),
      _c(
        "van-button",
        {
          staticStyle: {
            width: "100%",
            margin: "30px 0",
            background: "#3EB3F0",
            color: "#fff",
          },
          attrs: { size: "normal", round: "" },
          on: { click: _vm.submit },
        },
        [_vm._v("保存")]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.popupshow,
            callback: function ($$v) {
              _vm.popupshow = $$v
            },
            expression: "popupshow",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "item-height": 60,
              columns: _vm.columns,
            },
            on: {
              confirm: _vm.onChange,
              cancel: function ($event) {
                _vm.popupshow = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }