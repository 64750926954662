<template>
  <fb-page :bgColor="{background:'#eef7fc'}">
    <!-- header -->
    <fb-header
      slot="header"
      :title="'客户列表'"
      fixed
    >
      <fb-button
        slot="left"
        icon="back"
        @click="$router.back()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <div class="KL_layout_edit">
      <person-component :result="result" />
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
import PersonComponent from '../components/person'
export default {
  data () {
    return {
      result: {}
    }
  },
  components: { PersonComponent },
  async created () {
    try {
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: '加载中...'
      })
      const { id } = this.$route.params
      // const caseBase = '//192.168.0.124:10004'
      const response = await this.$axios.get(`${caseBase}/lts/customer/queryCustomer?customerId=${id}`)
      const { customer } = response.data.data
      this.result = customer
      this.$toast.clear()
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang='stylus'>
.KL_layout_edit {
  padding: 15px;
  height: calc(100vh - 40px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
